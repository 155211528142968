/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Value object for time range
 * @export
 * @interface TimeRange
 */
export interface TimeRange {
    /**
     * Start date
     * @type {Date}
     * @memberof TimeRange
     */
    startDate: Date;
    /**
     * End date
     * @type {Date}
     * @memberof TimeRange
     */
    endDate?: Date;
}

export function TimeRangeFromJSON(json: any): TimeRange {
    return TimeRangeFromJSONTyped(json, false);
}

export function TimeRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
    };
}

export function TimeRangeToJSON(value?: TimeRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': (value.startDate.toISOString().substr(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
    };
}


