/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Schema for an attachment
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * Identifier of the attachment
     * @type {number}
     * @memberof Attachment
     */
    id: number;
    /**
     * Name of the attachment
     * @type {string}
     * @memberof Attachment
     */
    name: string;
    /**
     * Upload date of the attachment
     * @type {Date}
     * @memberof Attachment
     */
    uploadDate: Date;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'uploadDate': (new Date(json['uploadDate'])),
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'uploadDate': (value.uploadDate.toISOString().substr(0,10)),
    };
}


