/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    Quantity,
    QuantityFromJSON,
    QuantityFromJSONTyped,
    QuantityToJSON,
} from './';

/**
 * Schema for creating a new product
 * @export
 * @interface CreateProduct
 */
export interface CreateProduct {
    /**
     * Name of the product
     * @type {string}
     * @memberof CreateProduct
     */
    name: string;
    /**
     * 
     * @type {Amount}
     * @memberof CreateProduct
     */
    amount: Amount;
    /**
     * 
     * @type {Quantity}
     * @memberof CreateProduct
     */
    quantity: Quantity;
}

export function CreateProductFromJSON(json: any): CreateProduct {
    return CreateProductFromJSONTyped(json, false);
}

export function CreateProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'amount': AmountFromJSON(json['amount']),
        'quantity': QuantityFromJSON(json['quantity']),
    };
}

export function CreateProductToJSON(value?: CreateProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'amount': AmountToJSON(value.amount),
        'quantity': QuantityToJSON(value.quantity),
    };
}


