/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateProduct,
    CreateProductFromJSON,
    CreateProductToJSON,
    CreateVariableTransaction,
    CreateVariableTransactionFromJSON,
    CreateVariableTransactionToJSON,
    Product,
    ProductFromJSON,
    ProductToJSON,
    UpdateVariableTransaction,
    UpdateVariableTransactionFromJSON,
    UpdateVariableTransactionToJSON,
    VariableTransaction,
    VariableTransactionFromJSON,
    VariableTransactionToJSON,
} from '../models';

export interface CreateProductRequest {
    transactionId: number;
    createProduct: CreateProduct;
    accept?: CreateProductAcceptEnum;
}

export interface CreateVariableTransactionRequest {
    createVariableTransaction: CreateVariableTransaction;
    accept?: CreateVariableTransactionAcceptEnum;
}

export interface DeleteProductRequest {
    transactionId: number;
    productId: number;
    accept?: DeleteProductAcceptEnum;
}

export interface DeleteProductsRequest {
    transactionId: number;
    productIds: Array<number>;
    accept?: DeleteProductsAcceptEnum;
}

export interface DeleteVariableTransactionRequest {
    transactionId: number;
    accept?: DeleteVariableTransactionAcceptEnum;
}

export interface GetVariableTransactionByIdRequest {
    transactionId: number;
    accept?: GetVariableTransactionByIdAcceptEnum;
}

export interface UpdateVariableTransactionRequest {
    transactionId: number;
    updateVariableTransaction: UpdateVariableTransaction;
    accept?: UpdateVariableTransactionAcceptEnum;
}

/**
 * no description
 */
export class VariableTransactionApi extends runtime.BaseAPI {

    /**
     * Creates a new product
     */
    async createProductRaw(requestParameters: CreateProductRequest): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling createProduct.');
        }

        if (requestParameters.createProduct === null || requestParameters.createProduct === undefined) {
            throw new runtime.RequiredError('createProduct','Required parameter requestParameters.createProduct was null or undefined when calling createProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}/products`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateProductToJSON(requestParameters.createProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Creates a new product
     */
    async createProduct(requestParameters: CreateProductRequest): Promise<Product> {
        const response = await this.createProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new variable transaction
     */
    async createVariableTransactionRaw(requestParameters: CreateVariableTransactionRequest): Promise<runtime.ApiResponse<VariableTransaction>> {
        if (requestParameters.createVariableTransaction === null || requestParameters.createVariableTransaction === undefined) {
            throw new runtime.RequiredError('createVariableTransaction','Required parameter requestParameters.createVariableTransaction was null or undefined when calling createVariableTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVariableTransactionToJSON(requestParameters.createVariableTransaction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableTransactionFromJSON(jsonValue));
    }

    /**
     * Creates a new variable transaction
     */
    async createVariableTransaction(requestParameters: CreateVariableTransactionRequest): Promise<VariableTransaction> {
        const response = await this.createVariableTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a product
     */
    async deleteProductRaw(requestParameters: DeleteProductRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteProduct.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling deleteProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}/products/{productId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a product
     */
    async deleteProduct(requestParameters: DeleteProductRequest): Promise<void> {
        await this.deleteProductRaw(requestParameters);
    }

    /**
     * Deletes multiple products
     */
    async deleteProductsRaw(requestParameters: DeleteProductsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteProducts.');
        }

        if (requestParameters.productIds === null || requestParameters.productIds === undefined) {
            throw new runtime.RequiredError('productIds','Required parameter requestParameters.productIds was null or undefined when calling deleteProducts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productIds) {
            queryParameters['productIds'] = requestParameters.productIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}/products`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes multiple products
     */
    async deleteProducts(requestParameters: DeleteProductsRequest): Promise<void> {
        await this.deleteProductsRaw(requestParameters);
    }

    /**
     * Deletes a variable transaction
     */
    async deleteVariableTransactionRaw(requestParameters: DeleteVariableTransactionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteVariableTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a variable transaction
     */
    async deleteVariableTransaction(requestParameters: DeleteVariableTransactionRequest): Promise<void> {
        await this.deleteVariableTransactionRaw(requestParameters);
    }

    /**
     * Fetches a variable transaction by id
     */
    async getVariableTransactionByIdRaw(requestParameters: GetVariableTransactionByIdRequest): Promise<runtime.ApiResponse<VariableTransaction>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling getVariableTransactionById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableTransactionFromJSON(jsonValue));
    }

    /**
     * Fetches a variable transaction by id
     */
    async getVariableTransactionById(requestParameters: GetVariableTransactionByIdRequest): Promise<VariableTransaction> {
        const response = await this.getVariableTransactionByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a variable transaction
     */
    async updateVariableTransactionRaw(requestParameters: UpdateVariableTransactionRequest): Promise<runtime.ApiResponse<VariableTransaction>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling updateVariableTransaction.');
        }

        if (requestParameters.updateVariableTransaction === null || requestParameters.updateVariableTransaction === undefined) {
            throw new runtime.RequiredError('updateVariableTransaction','Required parameter requestParameters.updateVariableTransaction was null or undefined when calling updateVariableTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateVariableTransactionToJSON(requestParameters.updateVariableTransaction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VariableTransactionFromJSON(jsonValue));
    }

    /**
     * Updates a variable transaction
     */
    async updateVariableTransaction(requestParameters: UpdateVariableTransactionRequest): Promise<VariableTransaction> {
        const response = await this.updateVariableTransactionRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateProductAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateVariableTransactionAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteProductAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteProductsAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteVariableTransactionAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetVariableTransactionByIdAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateVariableTransactionAcceptEnum {
    ApplicationJson = 'application/json'
}
