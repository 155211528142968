/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Schema for creating a new attachment
 * @export
 * @interface CreateAttachment
 */
export interface CreateAttachment {
    /**
     * Name of the attachment
     * @type {string}
     * @memberof CreateAttachment
     */
    name: string;
    /**
     * Content of the attachment
     * @type {Blob}
     * @memberof CreateAttachment
     */
    content: Blob;
}

export function CreateAttachmentFromJSON(json: any): CreateAttachment {
    return CreateAttachmentFromJSONTyped(json, false);
}

export function CreateAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'content': json['content'],
    };
}

export function CreateAttachmentToJSON(value?: CreateAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'content': value.content,
    };
}


