/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BirthDate,
    BirthDateFromJSON,
    BirthDateFromJSONTyped,
    BirthDateToJSON,
    Email,
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
    Link,
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
    Name,
    NameFromJSON,
    NameFromJSONTyped,
    NameToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    Settings,
    SettingsFromJSON,
    SettingsFromJSONTyped,
    SettingsToJSON,
    Token,
    TokenFromJSON,
    TokenFromJSONTyped,
    TokenToJSON,
} from './';

/**
 * Schema for a user
 * @export
 * @interface User
 */
export interface User {
    /**
     * Identifier of the user
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {Email}
     * @memberof User
     */
    email: Email;
    /**
     * 
     * @type {Name}
     * @memberof User
     */
    name: Name;
    /**
     * 
     * @type {BirthDate}
     * @memberof User
     */
    birthDate: BirthDate;
    /**
     * 
     * @type {Gender}
     * @memberof User
     */
    gender: Gender;
    /**
     * 
     * @type {Token}
     * @memberof User
     */
    activeToken: Token;
    /**
     * List of all tokens that are assigned to the user
     * @type {Array<Token>}
     * @memberof User
     */
    tokens?: Array<Token>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    roles?: Array<Role>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    verified: boolean;
    /**
     * 
     * @type {{ [key: string]: Settings; }}
     * @memberof User
     */
    settings?: { [key: string]: Settings; };
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof User
     */
    links?: { [key: string]: Link; };
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': EmailFromJSON(json['email']),
        'name': NameFromJSON(json['name']),
        'birthDate': BirthDateFromJSON(json['birthDate']),
        'gender': GenderFromJSON(json['gender']),
        'activeToken': TokenFromJSON(json['activeToken']),
        'tokens': !exists(json, 'tokens') ? undefined : ((json['tokens'] as Array<any>).map(TokenFromJSON)),
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(RoleFromJSON)),
        'verified': json['verified'],
        'settings': !exists(json, 'settings') ? undefined : (mapValues(json['settings'], SettingsFromJSON)),
        'links': !exists(json, '_links') ? undefined : (mapValues(json['_links'], LinkFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': EmailToJSON(value.email),
        'name': NameToJSON(value.name),
        'birthDate': BirthDateToJSON(value.birthDate),
        'gender': GenderToJSON(value.gender),
        'activeToken': TokenToJSON(value.activeToken),
        'tokens': value.tokens === undefined ? undefined : ((value.tokens as Array<any>).map(TokenToJSON)),
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(RoleToJSON)),
        'verified': value.verified,
        'settings': value.settings === undefined ? undefined : (mapValues(value.settings, SettingsToJSON)),
        '_links': value.links === undefined ? undefined : (mapValues(value.links, LinkToJSON)),
    };
}


