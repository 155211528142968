/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Token string of the token
 * @export
 * @interface TokenString
 */
export interface TokenString {
    /**
     * Token string
     * @type {string}
     * @memberof TokenString
     */
    token: string;
}

export function TokenStringFromJSON(json: any): TokenString {
    return TokenStringFromJSONTyped(json, false);
}

export function TokenStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function TokenStringToJSON(value?: TokenString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}


