/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryClassEnum,
    CategoryClassEnumFromJSON,
    CategoryClassEnumFromJSONTyped,
    CategoryClassEnumToJSON,
} from './';

/**
 * Schema for updating a category
 * @export
 * @interface UpdateCategory
 */
export interface UpdateCategory {
    /**
     * Name of the category
     * @type {string}
     * @memberof UpdateCategory
     */
    name?: string;
    /**
     * 
     * @type {CategoryClassEnum}
     * @memberof UpdateCategory
     */
    categoryClass?: CategoryClassEnum;
    /**
     * Id of the parent category
     * @type {number}
     * @memberof UpdateCategory
     */
    parentId?: number;
}

export function UpdateCategoryFromJSON(json: any): UpdateCategory {
    return UpdateCategoryFromJSONTyped(json, false);
}

export function UpdateCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'categoryClass': !exists(json, 'categoryClass') ? undefined : CategoryClassEnumFromJSON(json['categoryClass']),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
    };
}

export function UpdateCategoryToJSON(value?: UpdateCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'categoryClass': CategoryClassEnumToJSON(value.categoryClass),
        'parentId': value.parentId,
    };
}


