/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateAttachment,
    CreateAttachmentFromJSON,
    CreateAttachmentFromJSONTyped,
    CreateAttachmentToJSON,
    CreateProduct,
    CreateProductFromJSON,
    CreateProductFromJSONTyped,
    CreateProductToJSON,
    ValueDate,
    ValueDateFromJSON,
    ValueDateFromJSONTyped,
    ValueDateToJSON,
} from './';

/**
 * Schema for creating a new variable transaction
 * @export
 * @interface CreateVariableTransaction
 */
export interface CreateVariableTransaction {
    /**
     * Category ID of the variable transaction
     * @type {number}
     * @memberof CreateVariableTransaction
     */
    categoryId: number;
    /**
     * 
     * @type {ValueDate}
     * @memberof CreateVariableTransaction
     */
    valueDate: ValueDate;
    /**
     * Description of the variable transaction
     * @type {string}
     * @memberof CreateVariableTransaction
     */
    description?: string;
    /**
     * Vendor of the variable transaction
     * @type {string}
     * @memberof CreateVariableTransaction
     */
    vendor?: string;
    /**
     * List of attachments of the variable transaction
     * @type {Array<CreateAttachment>}
     * @memberof CreateVariableTransaction
     */
    attachments?: Array<CreateAttachment>;
    /**
     * List of products of the variable transaction
     * @type {Array<CreateProduct>}
     * @memberof CreateVariableTransaction
     */
    products?: Array<CreateProduct>;
}

export function CreateVariableTransactionFromJSON(json: any): CreateVariableTransaction {
    return CreateVariableTransactionFromJSONTyped(json, false);
}

export function CreateVariableTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateVariableTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': json['categoryId'],
        'valueDate': ValueDateFromJSON(json['valueDate']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(CreateAttachmentFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(CreateProductFromJSON)),
    };
}

export function CreateVariableTransactionToJSON(value?: CreateVariableTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'valueDate': ValueDateToJSON(value.valueDate),
        'description': value.description,
        'vendor': value.vendor,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(CreateAttachmentToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(CreateProductToJSON)),
    };
}


