/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Admin Configuration schema
 * @export
 * @interface AdminConfiguration
 */
export interface AdminConfiguration {
    /**
     * Default language for server
     * @type {string}
     * @memberof AdminConfiguration
     */
    defaultLanguage?: AdminConfigurationDefaultLanguageEnum;
    /**
     * Default currency for server
     * @type {string}
     * @memberof AdminConfiguration
     */
    defaultCurrency?: AdminConfigurationDefaultCurrencyEnum;
}

export function AdminConfigurationFromJSON(json: any): AdminConfiguration {
    return AdminConfigurationFromJSONTyped(json, false);
}

export function AdminConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultLanguage': !exists(json, 'defaultLanguage') ? undefined : json['defaultLanguage'],
        'defaultCurrency': !exists(json, 'defaultCurrency') ? undefined : json['defaultCurrency'],
    };
}

export function AdminConfigurationToJSON(value?: AdminConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultLanguage': value.defaultLanguage,
        'defaultCurrency': value.defaultCurrency,
    };
}

/**
* @export
* @enum {string}
*/
export enum AdminConfigurationDefaultLanguageEnum {
    En = 'en',
    De = 'de'
}
/**
* @export
* @enum {string}
*/
export enum AdminConfigurationDefaultCurrencyEnum {
    USD = 'USD',
    EUR = 'EUR'
}


