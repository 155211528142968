/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    Quantity,
    QuantityFromJSON,
    QuantityFromJSONTyped,
    QuantityToJSON,
} from './';

/**
 * Schema of a product
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Identifier of the product
     * @type {number}
     * @memberof Product
     */
    id: number;
    /**
     * Name of the product
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {Amount}
     * @memberof Product
     */
    amount: Amount;
    /**
     * 
     * @type {Quantity}
     * @memberof Product
     */
    quantity: Quantity;
    /**
     * 
     * @type {Amount}
     * @memberof Product
     */
    totalAmount: Amount;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'amount': AmountFromJSON(json['amount']),
        'quantity': QuantityFromJSON(json['quantity']),
        'totalAmount': AmountFromJSON(json['totalAmount']),
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'amount': AmountToJSON(value.amount),
        'quantity': QuantityToJSON(value.quantity),
        'totalAmount': AmountToJSON(value.totalAmount),
    };
}


