/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FixedTransaction,
    FixedTransactionFromJSON,
    FixedTransactionFromJSONTyped,
    FixedTransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface PagedModelFixedTransactionEmbedded
 */
export interface PagedModelFixedTransactionEmbedded {
    /**
     * 
     * @type {Array<FixedTransaction>}
     * @memberof PagedModelFixedTransactionEmbedded
     */
    fixedTransactionDToes?: Array<FixedTransaction>;
}

export function PagedModelFixedTransactionEmbeddedFromJSON(json: any): PagedModelFixedTransactionEmbedded {
    return PagedModelFixedTransactionEmbeddedFromJSONTyped(json, false);
}

export function PagedModelFixedTransactionEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedModelFixedTransactionEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fixedTransactionDToes': !exists(json, 'fixedTransactionDToes') ? undefined : ((json['fixedTransactionDToes'] as Array<any>).map(FixedTransactionFromJSON)),
    };
}

export function PagedModelFixedTransactionEmbeddedToJSON(value?: PagedModelFixedTransactionEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fixedTransactionDToes': value.fixedTransactionDToes === undefined ? undefined : ((value.fixedTransactionDToes as Array<any>).map(FixedTransactionToJSON)),
    };
}


