/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ValueDate,
    ValueDateFromJSON,
    ValueDateFromJSONTyped,
    ValueDateToJSON,
} from './';

/**
 * Schema for updating a variable transaction
 * @export
 * @interface UpdateVariableTransaction
 */
export interface UpdateVariableTransaction {
    /**
     * Category ID of the variable transaction
     * @type {number}
     * @memberof UpdateVariableTransaction
     */
    categoryId: number;
    /**
     * 
     * @type {ValueDate}
     * @memberof UpdateVariableTransaction
     */
    valueDate: ValueDate;
    /**
     * Description of the variable transaction
     * @type {string}
     * @memberof UpdateVariableTransaction
     */
    description?: string;
    /**
     * Vendor of the variable transaction
     * @type {string}
     * @memberof UpdateVariableTransaction
     */
    vendor?: string;
}

export function UpdateVariableTransactionFromJSON(json: any): UpdateVariableTransaction {
    return UpdateVariableTransactionFromJSONTyped(json, false);
}

export function UpdateVariableTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateVariableTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': json['categoryId'],
        'valueDate': ValueDateFromJSON(json['valueDate']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
    };
}

export function UpdateVariableTransactionToJSON(value?: UpdateVariableTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'valueDate': ValueDateToJSON(value.valueDate),
        'description': value.description,
        'vendor': value.vendor,
    };
}


