/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Values that can be applies to the category class
 * @export
 * @enum {string}
 */
export enum CategoryClassEnum {
    FIXEDREVENUE = 'FIXED_REVENUE',
    VARIABLEREVENUE = 'VARIABLE_REVENUE',
    FIXEDEXPENSES = 'FIXED_EXPENSES',
    VARIABLEEXPENSES = 'VARIABLE_EXPENSES'
}

export function CategoryClassEnumFromJSON(json: any): CategoryClassEnum {
    return CategoryClassEnumFromJSONTyped(json, false);
}

export function CategoryClassEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryClassEnum {
    return json as CategoryClassEnum;
}

export function CategoryClassEnumToJSON(value?: CategoryClassEnum | null): any {
    return value as any;
}

