/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    Link,
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
    ValueDate,
    ValueDateFromJSON,
    ValueDateFromJSONTyped,
    ValueDateToJSON,
} from './';

/**
 * Schema of a variable transaction
 * @export
 * @interface VariableTransaction
 */
export interface VariableTransaction {
    /**
     * Identifier of the variable transaction
     * @type {number}
     * @memberof VariableTransaction
     */
    id: number;
    /**
     * 
     * @type {Category}
     * @memberof VariableTransaction
     */
    category: Category;
    /**
     * 
     * @type {ValueDate}
     * @memberof VariableTransaction
     */
    valueDate: ValueDate;
    /**
     * Description of the variable transaction
     * @type {string}
     * @memberof VariableTransaction
     */
    description?: string;
    /**
     * Vendor of the variable transaction
     * @type {string}
     * @memberof VariableTransaction
     */
    vendor?: string;
    /**
     * List of attachments of the variable transaction
     * @type {Array<Attachment>}
     * @memberof VariableTransaction
     */
    attachments?: Array<Attachment>;
    /**
     * List of products of the variable transaction
     * @type {Array<Product>}
     * @memberof VariableTransaction
     */
    products?: Array<Product>;
    /**
     * 
     * @type {Amount}
     * @memberof VariableTransaction
     */
    totalAmount: Amount;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof VariableTransaction
     */
    links?: { [key: string]: Link; };
}

export function VariableTransactionFromJSON(json: any): VariableTransaction {
    return VariableTransactionFromJSONTyped(json, false);
}

export function VariableTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'category': CategoryFromJSON(json['category']),
        'valueDate': ValueDateFromJSON(json['valueDate']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductFromJSON)),
        'totalAmount': AmountFromJSON(json['totalAmount']),
        'links': !exists(json, '_links') ? undefined : (mapValues(json['_links'], LinkFromJSON)),
    };
}

export function VariableTransactionToJSON(value?: VariableTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category': CategoryToJSON(value.category),
        'valueDate': ValueDateToJSON(value.valueDate),
        'description': value.description,
        'vendor': value.vendor,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductToJSON)),
        'totalAmount': AmountToJSON(value.totalAmount),
        '_links': value.links === undefined ? undefined : (mapValues(value.links, LinkToJSON)),
    };
}


