/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Value object for amount
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * Amount property
     * @type {number}
     * @memberof Amount
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Amount
     */
    notNull?: boolean;
}

export function AmountFromJSON(json: any): Amount {
    return AmountFromJSONTyped(json, false);
}

export function AmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Amount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'notNull': !exists(json, 'notNull') ? undefined : json['notNull'],
    };
}

export function AmountToJSON(value?: Amount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'notNull': value.notNull,
    };
}


