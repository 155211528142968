/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExpireDate,
    ExpireDateFromJSON,
    ExpireDateFromJSONTyped,
    ExpireDateToJSON,
    IPAddress,
    IPAddressFromJSON,
    IPAddressFromJSONTyped,
    IPAddressToJSON,
    OperatingSystem,
    OperatingSystemFromJSON,
    OperatingSystemFromJSONTyped,
    OperatingSystemToJSON,
    TokenString,
    TokenStringFromJSON,
    TokenStringFromJSONTyped,
    TokenStringToJSON,
} from './';

/**
 * Schema of a token. A token is used to authorize the user when calling the API.
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * Identifier of the token
     * @type {number}
     * @memberof Token
     */
    id: number;
    /**
     * 
     * @type {TokenString}
     * @memberof Token
     */
    token: TokenString;
    /**
     * 
     * @type {IPAddress}
     * @memberof Token
     */
    ipAddress: IPAddress;
    /**
     * 
     * @type {ExpireDate}
     * @memberof Token
     */
    expireDate: ExpireDate;
    /**
     * 
     * @type {OperatingSystem}
     * @memberof Token
     */
    operatingSystem: OperatingSystem;
}

export function TokenFromJSON(json: any): Token {
    return TokenFromJSONTyped(json, false);
}

export function TokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): Token {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'token': TokenStringFromJSON(json['token']),
        'ipAddress': IPAddressFromJSON(json['ipAddress']),
        'expireDate': ExpireDateFromJSON(json['expireDate']),
        'operatingSystem': OperatingSystemFromJSON(json['operatingSystem']),
    };
}

export function TokenToJSON(value?: Token | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': TokenStringToJSON(value.token),
        'ipAddress': IPAddressToJSON(value.ipAddress),
        'expireDate': ExpireDateToJSON(value.expireDate),
        'operatingSystem': OperatingSystemToJSON(value.operatingSystem),
    };
}


