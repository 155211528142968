/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Value object for IP address
 * @export
 * @interface IPAddress
 */
export interface IPAddress {
    /**
     * IP address
     * @type {string}
     * @memberof IPAddress
     */
    ipAddress: string;
}

export function IPAddressFromJSON(json: any): IPAddress {
    return IPAddressFromJSONTyped(json, false);
}

export function IPAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): IPAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ipAddress': json['ipAddress'],
    };
}

export function IPAddressToJSON(value?: IPAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ipAddress': value.ipAddress,
    };
}


