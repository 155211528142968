/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Link,
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './';

/**
 * Set of records
 * @export
 * @interface DataSet
 */
export interface DataSet {
    /**
     * Data Records
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof DataSet
     */
    records?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof DataSet
     */
    links?: { [key: string]: Link; };
}

export function DataSetFromJSON(json: any): DataSet {
    return DataSetFromJSONTyped(json, false);
}

export function DataSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'records': !exists(json, 'records') ? undefined : json['records'],
        'links': !exists(json, '_links') ? undefined : (mapValues(json['_links'], LinkFromJSON)),
    };
}

export function DataSetToJSON(value?: DataSet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'records': value.records,
        '_links': value.links === undefined ? undefined : (mapValues(value.links, LinkToJSON)),
    };
}


