/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Update Admin Configuration Schema
 * @export
 * @interface UpdateAdminConfiguration
 */
export interface UpdateAdminConfiguration {
    /**
     * Default language for server
     * @type {string}
     * @memberof UpdateAdminConfiguration
     */
    defaultLanguage?: UpdateAdminConfigurationDefaultLanguageEnum;
    /**
     * Default currency for server
     * @type {string}
     * @memberof UpdateAdminConfiguration
     */
    defaultCurrency?: UpdateAdminConfigurationDefaultCurrencyEnum;
}

export function UpdateAdminConfigurationFromJSON(json: any): UpdateAdminConfiguration {
    return UpdateAdminConfigurationFromJSONTyped(json, false);
}

export function UpdateAdminConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAdminConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultLanguage': !exists(json, 'defaultLanguage') ? undefined : json['defaultLanguage'],
        'defaultCurrency': !exists(json, 'defaultCurrency') ? undefined : json['defaultCurrency'],
    };
}

export function UpdateAdminConfigurationToJSON(value?: UpdateAdminConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultLanguage': value.defaultLanguage,
        'defaultCurrency': value.defaultCurrency,
    };
}

/**
* @export
* @enum {string}
*/
export enum UpdateAdminConfigurationDefaultLanguageEnum {
    En = 'en',
    De = 'de'
}
/**
* @export
* @enum {string}
*/
export enum UpdateAdminConfigurationDefaultCurrencyEnum {
    USD = 'USD',
    EUR = 'EUR'
}


