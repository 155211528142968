/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Value object for hashed password
 * @export
 * @interface HashedPassword
 */
export interface HashedPassword {
    /**
     * Hashed password (SHA-256)
     * @type {string}
     * @memberof HashedPassword
     */
    hashedPassword: string;
    /**
     * Salt that is used to hash the password
     * @type {string}
     * @memberof HashedPassword
     */
    salt: string;
}

export function HashedPasswordFromJSON(json: any): HashedPassword {
    return HashedPasswordFromJSONTyped(json, false);
}

export function HashedPasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HashedPassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hashedPassword': json['hashedPassword'],
        'salt': json['salt'],
    };
}

export function HashedPasswordToJSON(value?: HashedPassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hashedPassword': value.hashedPassword,
        'salt': value.salt,
    };
}


