/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    ValueDate,
    ValueDateFromJSON,
    ValueDateFromJSONTyped,
    ValueDateToJSON,
} from './';

/**
 * Schema for creating a new fixed transaction amount
 * @export
 * @interface CreateFixedTransactionAmount
 */
export interface CreateFixedTransactionAmount {
    /**
     * 
     * @type {ValueDate}
     * @memberof CreateFixedTransactionAmount
     */
    valueDate: ValueDate;
    /**
     * 
     * @type {Amount}
     * @memberof CreateFixedTransactionAmount
     */
    amount: Amount;
}

export function CreateFixedTransactionAmountFromJSON(json: any): CreateFixedTransactionAmount {
    return CreateFixedTransactionAmountFromJSONTyped(json, false);
}

export function CreateFixedTransactionAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFixedTransactionAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valueDate': ValueDateFromJSON(json['valueDate']),
        'amount': AmountFromJSON(json['amount']),
    };
}

export function CreateFixedTransactionAmountToJSON(value?: CreateFixedTransactionAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valueDate': ValueDateToJSON(value.valueDate),
        'amount': AmountToJSON(value.amount),
    };
}


