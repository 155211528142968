/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryClassEnum,
    CategoryClassEnumFromJSON,
    CategoryClassEnumFromJSONTyped,
    CategoryClassEnumToJSON,
} from './';

/**
 * Schema for creating a new category
 * @export
 * @interface CreateCategory
 */
export interface CreateCategory {
    /**
     * Name of the category
     * @type {string}
     * @memberof CreateCategory
     */
    name: string;
    /**
     * 
     * @type {CategoryClassEnum}
     * @memberof CreateCategory
     */
    categoryClass: CategoryClassEnum;
    /**
     * Id of the parent category
     * @type {number}
     * @memberof CreateCategory
     */
    parentId?: number;
}

export function CreateCategoryFromJSON(json: any): CreateCategory {
    return CreateCategoryFromJSONTyped(json, false);
}

export function CreateCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'categoryClass': CategoryClassEnumFromJSON(json['categoryClass']),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
    };
}

export function CreateCategoryToJSON(value?: CreateCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'categoryClass': CategoryClassEnumToJSON(value.categoryClass),
        'parentId': value.parentId,
    };
}


