/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Schema for updating users settings
 * @export
 * @interface UpdateSettings
 */
export interface UpdateSettings {
    /**
     * Settings that will be updated
     * @type {{ [key: string]: string; }}
     * @memberof UpdateSettings
     */
    settings: { [key: string]: string; };
}

export function UpdateSettingsFromJSON(json: any): UpdateSettings {
    return UpdateSettingsFromJSONTyped(json, false);
}

export function UpdateSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': json['settings'],
    };
}

export function UpdateSettingsToJSON(value?: UpdateSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': value.settings,
    };
}


