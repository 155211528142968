/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VariableTransaction,
    VariableTransactionFromJSON,
    VariableTransactionFromJSONTyped,
    VariableTransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface PagedModelVariableTransactionEmbedded
 */
export interface PagedModelVariableTransactionEmbedded {
    /**
     * 
     * @type {Array<VariableTransaction>}
     * @memberof PagedModelVariableTransactionEmbedded
     */
    variableTransactionDToes?: Array<VariableTransaction>;
}

export function PagedModelVariableTransactionEmbeddedFromJSON(json: any): PagedModelVariableTransactionEmbedded {
    return PagedModelVariableTransactionEmbeddedFromJSONTyped(json, false);
}

export function PagedModelVariableTransactionEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedModelVariableTransactionEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'variableTransactionDToes': !exists(json, 'variableTransactionDToes') ? undefined : ((json['variableTransactionDToes'] as Array<any>).map(VariableTransactionFromJSON)),
    };
}

export function PagedModelVariableTransactionEmbeddedToJSON(value?: PagedModelVariableTransactionEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'variableTransactionDToes': value.variableTransactionDToes === undefined ? undefined : ((value.variableTransactionDToes as Array<any>).map(VariableTransactionToJSON)),
    };
}


