/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperatingSystemEnum,
    OperatingSystemEnumFromJSON,
    OperatingSystemEnumFromJSONTyped,
    OperatingSystemEnumToJSON,
} from './';

/**
 * Value object for the operating system
 * @export
 * @interface OperatingSystem
 */
export interface OperatingSystem {
    /**
     * 
     * @type {OperatingSystemEnum}
     * @memberof OperatingSystem
     */
    operatingSystem: OperatingSystemEnum;
}

export function OperatingSystemFromJSON(json: any): OperatingSystem {
    return OperatingSystemFromJSONTyped(json, false);
}

export function OperatingSystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatingSystem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operatingSystem': OperatingSystemEnumFromJSON(json['operatingSystem']),
    };
}

export function OperatingSystemToJSON(value?: OperatingSystem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operatingSystem': OperatingSystemEnumToJSON(value.operatingSystem),
    };
}


