/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegisterUser,
    RegisterUserFromJSON,
    RegisterUserFromJSONTyped,
    RegisterUserToJSON,
} from './';

/**
 * Initial Admin Configuration
 * @export
 * @interface InitAdminConfiguration
 */
export interface InitAdminConfiguration {
    /**
     * Default language for server
     * @type {string}
     * @memberof InitAdminConfiguration
     */
    defaultLanguage?: InitAdminConfigurationDefaultLanguageEnum;
    /**
     * Default currency for server
     * @type {string}
     * @memberof InitAdminConfiguration
     */
    defaultCurrency?: InitAdminConfigurationDefaultCurrencyEnum;
    /**
     * 
     * @type {RegisterUser}
     * @memberof InitAdminConfiguration
     */
    adminUser?: RegisterUser;
}

export function InitAdminConfigurationFromJSON(json: any): InitAdminConfiguration {
    return InitAdminConfigurationFromJSONTyped(json, false);
}

export function InitAdminConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitAdminConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultLanguage': !exists(json, 'defaultLanguage') ? undefined : json['defaultLanguage'],
        'defaultCurrency': !exists(json, 'defaultCurrency') ? undefined : json['defaultCurrency'],
        'adminUser': !exists(json, 'adminUser') ? undefined : RegisterUserFromJSON(json['adminUser']),
    };
}

export function InitAdminConfigurationToJSON(value?: InitAdminConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultLanguage': value.defaultLanguage,
        'defaultCurrency': value.defaultCurrency,
        'adminUser': RegisterUserToJSON(value.adminUser),
    };
}

/**
* @export
* @enum {string}
*/
export enum InitAdminConfigurationDefaultLanguageEnum {
    En = 'en',
    De = 'de'
}
/**
* @export
* @enum {string}
*/
export enum InitAdminConfigurationDefaultCurrencyEnum {
    USD = 'USD',
    EUR = 'EUR'
}


