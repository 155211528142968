/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentToJSON,
    AttachmentWithContent,
    AttachmentWithContentFromJSON,
    AttachmentWithContentToJSON,
    CreateAttachment,
    CreateAttachmentFromJSON,
    CreateAttachmentToJSON,
} from '../models';

export interface CreateAttachmentRequest {
    transactionId: number;
    createAttachment: CreateAttachment;
    accept?: CreateAttachmentAcceptEnum;
}

export interface CreateAttachment1Request {
    transactionId: number;
    createAttachment: CreateAttachment;
    accept?: CreateAttachment1AcceptEnum;
}

export interface DeleteTransactionRequest {
    transactionId: number;
    attachmentId: number;
    accept?: DeleteTransactionAcceptEnum;
}

export interface DeleteTransaction1Request {
    transactionId: number;
    attachmentId: number;
    accept?: DeleteTransaction1AcceptEnum;
}

export interface GetAttachmentRequest {
    transactionId: number;
    attachmentId: number;
    accept?: GetAttachmentAcceptEnum;
}

export interface GetAttachment1Request {
    transactionId: number;
    attachmentId: number;
    accept?: GetAttachment1AcceptEnum;
}

/**
 * no description
 */
export class AttachmentApi extends runtime.BaseAPI {

    /**
     * Creates a new attachment
     */
    async createAttachmentRaw(requestParameters: CreateAttachmentRequest): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling createAttachment.');
        }

        if (requestParameters.createAttachment === null || requestParameters.createAttachment === undefined) {
            throw new runtime.RequiredError('createAttachment','Required parameter requestParameters.createAttachment was null or undefined when calling createAttachment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}/attachments`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAttachmentToJSON(requestParameters.createAttachment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Creates a new attachment
     */
    async createAttachment(requestParameters: CreateAttachmentRequest): Promise<Attachment> {
        const response = await this.createAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new attachment
     */
    async createAttachment1Raw(requestParameters: CreateAttachment1Request): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling createAttachment1.');
        }

        if (requestParameters.createAttachment === null || requestParameters.createAttachment === undefined) {
            throw new runtime.RequiredError('createAttachment','Required parameter requestParameters.createAttachment was null or undefined when calling createAttachment1.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}/attachments`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAttachmentToJSON(requestParameters.createAttachment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Creates a new attachment
     */
    async createAttachment1(requestParameters: CreateAttachment1Request): Promise<Attachment> {
        const response = await this.createAttachment1Raw(requestParameters);
        return await response.value();
    }

    /**
     * Fetches an attachment with content
     */
    async deleteTransactionRaw(requestParameters: DeleteTransactionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteTransaction.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}/attachments/{attachmentId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Fetches an attachment with content
     */
    async deleteTransaction(requestParameters: DeleteTransactionRequest): Promise<void> {
        await this.deleteTransactionRaw(requestParameters);
    }

    /**
     * Fetches an attachment with content
     */
    async deleteTransaction1Raw(requestParameters: DeleteTransaction1Request): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteTransaction1.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteTransaction1.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}/attachments/{attachmentId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Fetches an attachment with content
     */
    async deleteTransaction1(requestParameters: DeleteTransaction1Request): Promise<void> {
        await this.deleteTransaction1Raw(requestParameters);
    }

    /**
     * Fetches an attachment with content
     */
    async getAttachmentRaw(requestParameters: GetAttachmentRequest): Promise<runtime.ApiResponse<AttachmentWithContent>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling getAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling getAttachment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/variableTransactions/{transactionId}/attachments/{attachmentId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentWithContentFromJSON(jsonValue));
    }

    /**
     * Fetches an attachment with content
     */
    async getAttachment(requestParameters: GetAttachmentRequest): Promise<AttachmentWithContent> {
        const response = await this.getAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetches an attachment with content
     */
    async getAttachment1Raw(requestParameters: GetAttachment1Request): Promise<runtime.ApiResponse<AttachmentWithContent>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling getAttachment1.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling getAttachment1.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}/attachments/{attachmentId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentWithContentFromJSON(jsonValue));
    }

    /**
     * Fetches an attachment with content
     */
    async getAttachment1(requestParameters: GetAttachment1Request): Promise<AttachmentWithContent> {
        const response = await this.getAttachment1Raw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateAttachmentAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateAttachment1AcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteTransactionAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteTransaction1AcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAttachmentAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAttachment1AcceptEnum {
    ApplicationJson = 'application/json'
}
