/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
    FixedTransactionAmount,
    FixedTransactionAmountFromJSON,
    FixedTransactionAmountFromJSONTyped,
    FixedTransactionAmountToJSON,
    Link,
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
    TimeRange,
    TimeRangeFromJSON,
    TimeRangeFromJSONTyped,
    TimeRangeToJSON,
} from './';

/**
 * Schema for a fixed transaction
 * @export
 * @interface FixedTransaction
 */
export interface FixedTransaction {
    /**
     * Identifier of the fixed transaction
     * @type {number}
     * @memberof FixedTransaction
     */
    id: number;
    /**
     * 
     * @type {Category}
     * @memberof FixedTransaction
     */
    category: Category;
    /**
     * 
     * @type {Amount}
     * @memberof FixedTransaction
     */
    amount: Amount;
    /**
     * 
     * @type {TimeRange}
     * @memberof FixedTransaction
     */
    timeRange: TimeRange;
    /**
     * Product of the fixed transaction
     * @type {string}
     * @memberof FixedTransaction
     */
    product: string;
    /**
     * Description of the fixed transaction
     * @type {string}
     * @memberof FixedTransaction
     */
    description: string;
    /**
     * Vendor of the fixed transaction
     * @type {string}
     * @memberof FixedTransaction
     */
    vendor: string;
    /**
     * Indicates whether this transaction is variable or not
     * @type {boolean}
     * @memberof FixedTransaction
     */
    hasVariableAmounts: boolean;
    /**
     * Billing day of the fixed transaction
     * @type {number}
     * @memberof FixedTransaction
     */
    day: number;
    /**
     * List of attachments of the variable transaction
     * @type {Array<Attachment>}
     * @memberof FixedTransaction
     */
    attachments?: Array<Attachment>;
    /**
     * List of fixed transaction amounts
     * @type {Array<FixedTransactionAmount>}
     * @memberof FixedTransaction
     */
    transactionAmounts: Array<FixedTransactionAmount>;
    /**
     * 
     * @type {boolean}
     * @memberof FixedTransaction
     */
    active?: boolean;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof FixedTransaction
     */
    links?: { [key: string]: Link; };
}

export function FixedTransactionFromJSON(json: any): FixedTransaction {
    return FixedTransactionFromJSONTyped(json, false);
}

export function FixedTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FixedTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'category': CategoryFromJSON(json['category']),
        'amount': AmountFromJSON(json['amount']),
        'timeRange': TimeRangeFromJSON(json['timeRange']),
        'product': json['product'],
        'description': json['description'],
        'vendor': json['vendor'],
        'hasVariableAmounts': json['hasVariableAmounts'],
        'day': json['day'],
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'transactionAmounts': ((json['transactionAmounts'] as Array<any>).map(FixedTransactionAmountFromJSON)),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'links': !exists(json, '_links') ? undefined : (mapValues(json['_links'], LinkFromJSON)),
    };
}

export function FixedTransactionToJSON(value?: FixedTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category': CategoryToJSON(value.category),
        'amount': AmountToJSON(value.amount),
        'timeRange': TimeRangeToJSON(value.timeRange),
        'product': value.product,
        'description': value.description,
        'vendor': value.vendor,
        'hasVariableAmounts': value.hasVariableAmounts,
        'day': value.day,
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'transactionAmounts': ((value.transactionAmounts as Array<any>).map(FixedTransactionAmountToJSON)),
        'active': value.active,
        '_links': value.links === undefined ? undefined : (mapValues(value.links, LinkToJSON)),
    };
}


