/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    CreateAttachment,
    CreateAttachmentFromJSON,
    CreateAttachmentFromJSONTyped,
    CreateAttachmentToJSON,
    CreateFixedTransactionAmount,
    CreateFixedTransactionAmountFromJSON,
    CreateFixedTransactionAmountFromJSONTyped,
    CreateFixedTransactionAmountToJSON,
    TimeRange,
    TimeRangeFromJSON,
    TimeRangeFromJSONTyped,
    TimeRangeToJSON,
} from './';

/**
 * Schema for creating a new fixed transaction
 * @export
 * @interface CreateFixedTransaction
 */
export interface CreateFixedTransaction {
    /**
     * Identifier of the category that will be assigned to the fixed transaction
     * @type {number}
     * @memberof CreateFixedTransaction
     */
    categoryId: number;
    /**
     * 
     * @type {Amount}
     * @memberof CreateFixedTransaction
     */
    amount: Amount;
    /**
     * 
     * @type {TimeRange}
     * @memberof CreateFixedTransaction
     */
    timeRange: TimeRange;
    /**
     * Product of the fixed transaction
     * @type {string}
     * @memberof CreateFixedTransaction
     */
    product: string;
    /**
     * Description of the fixed transaction
     * @type {string}
     * @memberof CreateFixedTransaction
     */
    description?: string;
    /**
     * Vendor of the fixed transaction
     * @type {string}
     * @memberof CreateFixedTransaction
     */
    vendor?: string;
    /**
     * Indicates whether this transaction is variable or not
     * @type {boolean}
     * @memberof CreateFixedTransaction
     */
    hasVariableAmounts: boolean;
    /**
     * Billing day of the fixed transaction
     * @type {number}
     * @memberof CreateFixedTransaction
     */
    day?: number;
    /**
     * List of fixed transaction amounts
     * @type {Array<CreateFixedTransactionAmount>}
     * @memberof CreateFixedTransaction
     */
    transactionAmounts?: Array<CreateFixedTransactionAmount>;
    /**
     * List of attachments of the variable transaction
     * @type {Array<CreateAttachment>}
     * @memberof CreateFixedTransaction
     */
    attachments?: Array<CreateAttachment>;
}

export function CreateFixedTransactionFromJSON(json: any): CreateFixedTransaction {
    return CreateFixedTransactionFromJSONTyped(json, false);
}

export function CreateFixedTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFixedTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': json['categoryId'],
        'amount': AmountFromJSON(json['amount']),
        'timeRange': TimeRangeFromJSON(json['timeRange']),
        'product': json['product'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'hasVariableAmounts': json['hasVariableAmounts'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'transactionAmounts': !exists(json, 'transactionAmounts') ? undefined : ((json['transactionAmounts'] as Array<any>).map(CreateFixedTransactionAmountFromJSON)),
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(CreateAttachmentFromJSON)),
    };
}

export function CreateFixedTransactionToJSON(value?: CreateFixedTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'amount': AmountToJSON(value.amount),
        'timeRange': TimeRangeToJSON(value.timeRange),
        'product': value.product,
        'description': value.description,
        'vendor': value.vendor,
        'hasVariableAmounts': value.hasVariableAmounts,
        'day': value.day,
        'transactionAmounts': value.transactionAmounts === undefined ? undefined : ((value.transactionAmounts as Array<any>).map(CreateFixedTransactionAmountToJSON)),
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(CreateAttachmentToJSON)),
    };
}


