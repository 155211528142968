/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface PagedModelUserEmbedded
 */
export interface PagedModelUserEmbedded {
    /**
     * 
     * @type {Array<User>}
     * @memberof PagedModelUserEmbedded
     */
    userDToes?: Array<User>;
}

export function PagedModelUserEmbeddedFromJSON(json: any): PagedModelUserEmbedded {
    return PagedModelUserEmbeddedFromJSONTyped(json, false);
}

export function PagedModelUserEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedModelUserEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userDToes': !exists(json, 'userDToes') ? undefined : ((json['userDToes'] as Array<any>).map(UserFromJSON)),
    };
}

export function PagedModelUserEmbeddedToJSON(value?: PagedModelUserEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userDToes': value.userDToes === undefined ? undefined : ((value.userDToes as Array<any>).map(UserToJSON)),
    };
}


