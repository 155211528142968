/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Value object for users name
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * First name of the user
     * @type {string}
     * @memberof Name
     */
    firstName: string;
    /**
     * Surname of the user
     * @type {string}
     * @memberof Name
     */
    surname: string;
}

export function NameFromJSON(json: any): Name {
    return NameFromJSONTyped(json, false);
}

export function NameFromJSONTyped(json: any, ignoreDiscriminator: boolean): Name {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'surname': json['surname'],
    };
}

export function NameToJSON(value?: Name | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'surname': value.surname,
    };
}


