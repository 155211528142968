/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Values that can be applied to the gender
 * @export
 * @enum {string}
 */
export enum GenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NOTSPECIFIED = 'NOT_SPECIFIED'
}

export function GenderEnumFromJSON(json: any): GenderEnum {
    return GenderEnumFromJSONTyped(json, false);
}

export function GenderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderEnum {
    return json as GenderEnum;
}

export function GenderEnumToJSON(value?: GenderEnum | null): any {
    return value as any;
}

