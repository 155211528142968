/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    ValueDate,
    ValueDateFromJSON,
    ValueDateFromJSONTyped,
    ValueDateToJSON,
} from './';

/**
 * Schema for update fixed transaction amount
 * @export
 * @interface UpdateFixedTransactionAmount
 */
export interface UpdateFixedTransactionAmount {
    /**
     * 
     * @type {ValueDate}
     * @memberof UpdateFixedTransactionAmount
     */
    valueDate?: ValueDate;
    /**
     * 
     * @type {Amount}
     * @memberof UpdateFixedTransactionAmount
     */
    amount?: Amount;
}

export function UpdateFixedTransactionAmountFromJSON(json: any): UpdateFixedTransactionAmount {
    return UpdateFixedTransactionAmountFromJSONTyped(json, false);
}

export function UpdateFixedTransactionAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFixedTransactionAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valueDate': !exists(json, 'valueDate') ? undefined : ValueDateFromJSON(json['valueDate']),
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
    };
}

export function UpdateFixedTransactionAmountToJSON(value?: UpdateFixedTransactionAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valueDate': ValueDateToJSON(value.valueDate),
        'amount': AmountToJSON(value.amount),
    };
}


