/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DataSet,
    DataSetFromJSON,
    DataSetToJSON,
} from '../models';

export interface GetCategoryDistributionRequest {
    userId: number;
    balanceType?: GetCategoryDistributionBalanceTypeEnum;
    numberOfMonths?: number;
    accept?: GetCategoryDistributionAcceptEnum;
}

export interface GetCategoryHistoryRequest {
    userId: number;
    categoryIds: Array<number>;
    numberOfMonths?: number;
    accept?: GetCategoryHistoryAcceptEnum;
}

export interface GetUsersBalanceHistoryRequest {
    userId: number;
    numberOfMonths?: number;
    accept?: GetUsersBalanceHistoryAcceptEnum;
}

export interface GetVariableTransactionCountHistoryRequest {
    userId: number;
    numberOfMonths?: number;
    accept?: GetVariableTransactionCountHistoryAcceptEnum;
}

/**
 * no description
 */
export class StatisticsApi extends runtime.BaseAPI {

    /**
     * Returns the history of the users category balance
     */
    async getCategoryDistributionRaw(requestParameters: GetCategoryDistributionRequest): Promise<runtime.ApiResponse<DataSet>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getCategoryDistribution.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.balanceType !== undefined) {
            queryParameters['balanceType'] = requestParameters.balanceType;
        }

        if (requestParameters.numberOfMonths !== undefined) {
            queryParameters['numberOfMonths'] = requestParameters.numberOfMonths;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/statistics/users/{userId}/categories/distribution`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetFromJSON(jsonValue));
    }

    /**
     * Returns the history of the users category balance
     */
    async getCategoryDistribution(requestParameters: GetCategoryDistributionRequest): Promise<DataSet> {
        const response = await this.getCategoryDistributionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the history of the users category balance
     */
    async getCategoryHistoryRaw(requestParameters: GetCategoryHistoryRequest): Promise<runtime.ApiResponse<DataSet>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getCategoryHistory.');
        }

        if (requestParameters.categoryIds === null || requestParameters.categoryIds === undefined) {
            throw new runtime.RequiredError('categoryIds','Required parameter requestParameters.categoryIds was null or undefined when calling getCategoryHistory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.categoryIds) {
            queryParameters['categoryIds'] = requestParameters.categoryIds;
        }

        if (requestParameters.numberOfMonths !== undefined) {
            queryParameters['numberOfMonths'] = requestParameters.numberOfMonths;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/statistics/users/{userId}/categories/history`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetFromJSON(jsonValue));
    }

    /**
     * Returns the history of the users category balance
     */
    async getCategoryHistory(requestParameters: GetCategoryHistoryRequest): Promise<DataSet> {
        const response = await this.getCategoryHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the history of the users balance
     */
    async getUsersBalanceHistoryRaw(requestParameters: GetUsersBalanceHistoryRequest): Promise<runtime.ApiResponse<DataSet>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUsersBalanceHistory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.numberOfMonths !== undefined) {
            queryParameters['numberOfMonths'] = requestParameters.numberOfMonths;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/statistics/users/{userId}/history`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetFromJSON(jsonValue));
    }

    /**
     * Returns the history of the users balance
     */
    async getUsersBalanceHistory(requestParameters: GetUsersBalanceHistoryRequest): Promise<DataSet> {
        const response = await this.getUsersBalanceHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the history of the users balance
     */
    async getVariableTransactionCountHistoryRaw(requestParameters: GetVariableTransactionCountHistoryRequest): Promise<runtime.ApiResponse<DataSet>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getVariableTransactionCountHistory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.numberOfMonths !== undefined) {
            queryParameters['numberOfMonths'] = requestParameters.numberOfMonths;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/statistics/users/{userId}/variableTransactions/count`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSetFromJSON(jsonValue));
    }

    /**
     * Returns the history of the users balance
     */
    async getVariableTransactionCountHistory(requestParameters: GetVariableTransactionCountHistoryRequest): Promise<DataSet> {
        const response = await this.getVariableTransactionCountHistoryRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetCategoryDistributionBalanceTypeEnum {
    Expenses = 'expenses',
    Revenue = 'revenue'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCategoryDistributionAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCategoryHistoryAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetUsersBalanceHistoryAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetVariableTransactionCountHistoryAcceptEnum {
    ApplicationJson = 'application/json'
}
