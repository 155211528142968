/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Link,
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
    PageMetadata,
    PageMetadataFromJSON,
    PageMetadataFromJSONTyped,
    PageMetadataToJSON,
    PagedModelUserEmbedded,
    PagedModelUserEmbeddedFromJSON,
    PagedModelUserEmbeddedFromJSONTyped,
    PagedModelUserEmbeddedToJSON,
} from './';

/**
 * 
 * @export
 * @interface PagedModelUser
 */
export interface PagedModelUser {
    /**
     * 
     * @type {PagedModelUserEmbedded}
     * @memberof PagedModelUser
     */
    embedded?: PagedModelUserEmbedded;
    /**
     * 
     * @type {{ [key: string]: Link; }}
     * @memberof PagedModelUser
     */
    links?: { [key: string]: Link; };
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelUser
     */
    page?: PageMetadata;
}

export function PagedModelUserFromJSON(json: any): PagedModelUser {
    return PagedModelUserFromJSONTyped(json, false);
}

export function PagedModelUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedModelUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'embedded': !exists(json, '_embedded') ? undefined : PagedModelUserEmbeddedFromJSON(json['_embedded']),
        'links': !exists(json, '_links') ? undefined : (mapValues(json['_links'], LinkFromJSON)),
        'page': !exists(json, 'page') ? undefined : PageMetadataFromJSON(json['page']),
    };
}

export function PagedModelUserToJSON(value?: PagedModelUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_embedded': PagedModelUserEmbeddedToJSON(value.embedded),
        '_links': value.links === undefined ? undefined : (mapValues(value.links, LinkToJSON)),
        'page': PageMetadataToJSON(value.page),
    };
}


