/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateFixedTransaction,
    CreateFixedTransactionFromJSON,
    CreateFixedTransactionToJSON,
    CreateFixedTransactionAmount,
    CreateFixedTransactionAmountFromJSON,
    CreateFixedTransactionAmountToJSON,
    FixedTransaction,
    FixedTransactionFromJSON,
    FixedTransactionToJSON,
    FixedTransactionAmount,
    FixedTransactionAmountFromJSON,
    FixedTransactionAmountToJSON,
    UpdateFixedTransaction,
    UpdateFixedTransactionFromJSON,
    UpdateFixedTransactionToJSON,
    UpdateFixedTransactionAmount,
    UpdateFixedTransactionAmountFromJSON,
    UpdateFixedTransactionAmountToJSON,
} from '../models';

export interface CreateFixedTransactionRequest {
    createFixedTransaction: CreateFixedTransaction;
    accept?: CreateFixedTransactionAcceptEnum;
}

export interface CreateFixedTransactionAmountRequest {
    transactionId: number;
    createFixedTransactionAmount: CreateFixedTransactionAmount;
    accept?: CreateFixedTransactionAmountAcceptEnum;
}

export interface DeleteFixedTransactionRequest {
    transactionId: number;
    accept?: DeleteFixedTransactionAcceptEnum;
}

export interface DeleteFixedTransactionAmountRequest {
    transactionId: number;
    fixedTransactionAmountId: number;
    accept?: DeleteFixedTransactionAmountAcceptEnum;
}

export interface DeleteFixedTransactionAmountsRequest {
    transactionId: number;
    fixedTransactionAmountIds: Array<number>;
    accept?: DeleteFixedTransactionAmountsAcceptEnum;
}

export interface GetFixedTransactionByIdRequest {
    transactionId: number;
    accept?: GetFixedTransactionByIdAcceptEnum;
}

export interface UpdateFixedTransactionRequest {
    transactionId: number;
    updateFixedTransaction: UpdateFixedTransaction;
    accept?: UpdateFixedTransactionAcceptEnum;
}

export interface UpdateTransactionAmountRequest {
    transactionId: number;
    transactionAmountId: number;
    updateFixedTransactionAmount: UpdateFixedTransactionAmount;
    accept?: UpdateTransactionAmountAcceptEnum;
}

/**
 * no description
 */
export class FixedTransactionApi extends runtime.BaseAPI {

    /**
     * Creates a new fixed transaction
     */
    async createFixedTransactionRaw(requestParameters: CreateFixedTransactionRequest): Promise<runtime.ApiResponse<FixedTransaction>> {
        if (requestParameters.createFixedTransaction === null || requestParameters.createFixedTransaction === undefined) {
            throw new runtime.RequiredError('createFixedTransaction','Required parameter requestParameters.createFixedTransaction was null or undefined when calling createFixedTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFixedTransactionToJSON(requestParameters.createFixedTransaction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedTransactionFromJSON(jsonValue));
    }

    /**
     * Creates a new fixed transaction
     */
    async createFixedTransaction(requestParameters: CreateFixedTransactionRequest): Promise<FixedTransaction> {
        const response = await this.createFixedTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new fixed transaction amount
     */
    async createFixedTransactionAmountRaw(requestParameters: CreateFixedTransactionAmountRequest): Promise<runtime.ApiResponse<FixedTransactionAmount>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling createFixedTransactionAmount.');
        }

        if (requestParameters.createFixedTransactionAmount === null || requestParameters.createFixedTransactionAmount === undefined) {
            throw new runtime.RequiredError('createFixedTransactionAmount','Required parameter requestParameters.createFixedTransactionAmount was null or undefined when calling createFixedTransactionAmount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}/transactionAmounts`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFixedTransactionAmountToJSON(requestParameters.createFixedTransactionAmount),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedTransactionAmountFromJSON(jsonValue));
    }

    /**
     * Creates a new fixed transaction amount
     */
    async createFixedTransactionAmount(requestParameters: CreateFixedTransactionAmountRequest): Promise<FixedTransactionAmount> {
        const response = await this.createFixedTransactionAmountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a fixed transaction
     */
    async deleteFixedTransactionRaw(requestParameters: DeleteFixedTransactionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteFixedTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a fixed transaction
     */
    async deleteFixedTransaction(requestParameters: DeleteFixedTransactionRequest): Promise<void> {
        await this.deleteFixedTransactionRaw(requestParameters);
    }

    /**
     * Deletes a fixed transaction amount
     */
    async deleteFixedTransactionAmountRaw(requestParameters: DeleteFixedTransactionAmountRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteFixedTransactionAmount.');
        }

        if (requestParameters.fixedTransactionAmountId === null || requestParameters.fixedTransactionAmountId === undefined) {
            throw new runtime.RequiredError('fixedTransactionAmountId','Required parameter requestParameters.fixedTransactionAmountId was null or undefined when calling deleteFixedTransactionAmount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}/transactionAmounts/{fixedTransactionAmountId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"fixedTransactionAmountId"}}`, encodeURIComponent(String(requestParameters.fixedTransactionAmountId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a fixed transaction amount
     */
    async deleteFixedTransactionAmount(requestParameters: DeleteFixedTransactionAmountRequest): Promise<void> {
        await this.deleteFixedTransactionAmountRaw(requestParameters);
    }

    /**
     * Deletes a fixed transaction amount
     */
    async deleteFixedTransactionAmountsRaw(requestParameters: DeleteFixedTransactionAmountsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling deleteFixedTransactionAmounts.');
        }

        if (requestParameters.fixedTransactionAmountIds === null || requestParameters.fixedTransactionAmountIds === undefined) {
            throw new runtime.RequiredError('fixedTransactionAmountIds','Required parameter requestParameters.fixedTransactionAmountIds was null or undefined when calling deleteFixedTransactionAmounts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fixedTransactionAmountIds) {
            queryParameters['fixedTransactionAmountIds'] = requestParameters.fixedTransactionAmountIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}/transactionAmounts`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a fixed transaction amount
     */
    async deleteFixedTransactionAmounts(requestParameters: DeleteFixedTransactionAmountsRequest): Promise<void> {
        await this.deleteFixedTransactionAmountsRaw(requestParameters);
    }

    /**
     * Fetches a fixed transaction by id
     */
    async getFixedTransactionByIdRaw(requestParameters: GetFixedTransactionByIdRequest): Promise<runtime.ApiResponse<FixedTransaction>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling getFixedTransactionById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedTransactionFromJSON(jsonValue));
    }

    /**
     * Fetches a fixed transaction by id
     */
    async getFixedTransactionById(requestParameters: GetFixedTransactionByIdRequest): Promise<FixedTransaction> {
        const response = await this.getFixedTransactionByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a fixed transaction
     */
    async updateFixedTransactionRaw(requestParameters: UpdateFixedTransactionRequest): Promise<runtime.ApiResponse<FixedTransaction>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling updateFixedTransaction.');
        }

        if (requestParameters.updateFixedTransaction === null || requestParameters.updateFixedTransaction === undefined) {
            throw new runtime.RequiredError('updateFixedTransaction','Required parameter requestParameters.updateFixedTransaction was null or undefined when calling updateFixedTransaction.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFixedTransactionToJSON(requestParameters.updateFixedTransaction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedTransactionFromJSON(jsonValue));
    }

    /**
     * Updates a fixed transaction
     */
    async updateFixedTransaction(requestParameters: UpdateFixedTransactionRequest): Promise<FixedTransaction> {
        const response = await this.updateFixedTransactionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a fixed transaction amount
     */
    async updateTransactionAmountRaw(requestParameters: UpdateTransactionAmountRequest): Promise<runtime.ApiResponse<FixedTransactionAmount>> {
        if (requestParameters.transactionId === null || requestParameters.transactionId === undefined) {
            throw new runtime.RequiredError('transactionId','Required parameter requestParameters.transactionId was null or undefined when calling updateTransactionAmount.');
        }

        if (requestParameters.transactionAmountId === null || requestParameters.transactionAmountId === undefined) {
            throw new runtime.RequiredError('transactionAmountId','Required parameter requestParameters.transactionAmountId was null or undefined when calling updateTransactionAmount.');
        }

        if (requestParameters.updateFixedTransactionAmount === null || requestParameters.updateFixedTransactionAmount === undefined) {
            throw new runtime.RequiredError('updateFixedTransactionAmount','Required parameter requestParameters.updateFixedTransactionAmount was null or undefined when calling updateTransactionAmount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fixedTransactions/{transactionId}/transactionAmounts/{transactionAmountId}`.replace(`{${"transactionId"}}`, encodeURIComponent(String(requestParameters.transactionId))).replace(`{${"transactionAmountId"}}`, encodeURIComponent(String(requestParameters.transactionAmountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFixedTransactionAmountToJSON(requestParameters.updateFixedTransactionAmount),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FixedTransactionAmountFromJSON(jsonValue));
    }

    /**
     * Updates a fixed transaction amount
     */
    async updateTransactionAmount(requestParameters: UpdateTransactionAmountRequest): Promise<FixedTransactionAmount> {
        const response = await this.updateTransactionAmountRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateFixedTransactionAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateFixedTransactionAmountAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteFixedTransactionAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteFixedTransactionAmountAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteFixedTransactionAmountsAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetFixedTransactionByIdAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateFixedTransactionAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateTransactionAmountAcceptEnum {
    ApplicationJson = 'application/json'
}
