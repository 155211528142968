/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminConfiguration,
    AdminConfigurationFromJSON,
    AdminConfigurationToJSON,
    InitAdminConfiguration,
    InitAdminConfigurationFromJSON,
    InitAdminConfigurationToJSON,
    PagedModelUser,
    PagedModelUserFromJSON,
    PagedModelUserToJSON,
    UpdateAdminConfiguration,
    UpdateAdminConfigurationFromJSON,
    UpdateAdminConfigurationToJSON,
} from '../models';

export interface GetConfigurationRequest {
    accept?: GetConfigurationAcceptEnum;
}

export interface GetUsersRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    accept?: GetUsersAcceptEnum;
}

export interface InitializeServerConfigurationRequest {
    initAdminConfiguration: InitAdminConfiguration;
    accept?: InitializeServerConfigurationAcceptEnum;
}

export interface UpdateConfigurationRequest {
    updateAdminConfiguration: UpdateAdminConfiguration;
    accept?: UpdateConfigurationAcceptEnum;
}

/**
 * no description
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     * Fetches the configuration of the Financer Server
     */
    async getConfigurationRaw(requestParameters: GetConfigurationRequest): Promise<runtime.ApiResponse<AdminConfiguration>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminConfigurationFromJSON(jsonValue));
    }

    /**
     * Fetches the configuration of the Financer Server
     */
    async getConfiguration(requestParameters: GetConfigurationRequest): Promise<AdminConfiguration> {
        const response = await this.getConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetches all users of the system
     */
    async getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<PagedModelUser>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedModelUserFromJSON(jsonValue));
    }

    /**
     * Fetches all users of the system
     */
    async getUsers(requestParameters: GetUsersRequest): Promise<PagedModelUser> {
        const response = await this.getUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Initializes the server configuration
     */
    async initializeServerConfigurationRaw(requestParameters: InitializeServerConfigurationRequest): Promise<runtime.ApiResponse<AdminConfiguration>> {
        if (requestParameters.initAdminConfiguration === null || requestParameters.initAdminConfiguration === undefined) {
            throw new runtime.RequiredError('initAdminConfiguration','Required parameter requestParameters.initAdminConfiguration was null or undefined when calling initializeServerConfiguration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/configuration`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InitAdminConfigurationToJSON(requestParameters.initAdminConfiguration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminConfigurationFromJSON(jsonValue));
    }

    /**
     * Initializes the server configuration
     */
    async initializeServerConfiguration(requestParameters: InitializeServerConfigurationRequest): Promise<AdminConfiguration> {
        const response = await this.initializeServerConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the configuration of the Financer Server
     */
    async updateConfigurationRaw(requestParameters: UpdateConfigurationRequest): Promise<runtime.ApiResponse<AdminConfiguration>> {
        if (requestParameters.updateAdminConfiguration === null || requestParameters.updateAdminConfiguration === undefined) {
            throw new runtime.RequiredError('updateAdminConfiguration','Required parameter requestParameters.updateAdminConfiguration was null or undefined when calling updateConfiguration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/configuration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAdminConfigurationToJSON(requestParameters.updateAdminConfiguration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminConfigurationFromJSON(jsonValue));
    }

    /**
     * Updates the configuration of the Financer Server
     */
    async updateConfiguration(requestParameters: UpdateConfigurationRequest): Promise<AdminConfiguration> {
        const response = await this.updateConfigurationRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetConfigurationAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetUsersAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum InitializeServerConfigurationAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateConfigurationAcceptEnum {
    ApplicationJson = 'application/json'
}
