/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    ValueDate,
    ValueDateFromJSON,
    ValueDateFromJSONTyped,
    ValueDateToJSON,
} from './';

/**
 * Schema of the fixed transaction amount
 * @export
 * @interface FixedTransactionAmount
 */
export interface FixedTransactionAmount {
    /**
     * Identifier of the fixed transaction amount
     * @type {number}
     * @memberof FixedTransactionAmount
     */
    id: number;
    /**
     * 
     * @type {ValueDate}
     * @memberof FixedTransactionAmount
     */
    valueDate: ValueDate;
    /**
     * 
     * @type {Amount}
     * @memberof FixedTransactionAmount
     */
    amount: Amount;
}

export function FixedTransactionAmountFromJSON(json: any): FixedTransactionAmount {
    return FixedTransactionAmountFromJSONTyped(json, false);
}

export function FixedTransactionAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): FixedTransactionAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'valueDate': ValueDateFromJSON(json['valueDate']),
        'amount': AmountFromJSON(json['amount']),
    };
}

export function FixedTransactionAmountToJSON(value?: FixedTransactionAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'valueDate': ValueDateToJSON(value.valueDate),
        'amount': AmountToJSON(value.amount),
    };
}


