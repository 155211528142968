/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HashedPassword,
    HashedPasswordFromJSON,
    HashedPasswordFromJSONTyped,
    HashedPasswordToJSON,
} from './';

/**
 * Schema for updating password
 * @export
 * @interface UpdatePassword
 */
export interface UpdatePassword {
    /**
     * Password of the current user
     * @type {string}
     * @memberof UpdatePassword
     */
    password: string;
    /**
     * 
     * @type {HashedPassword}
     * @memberof UpdatePassword
     */
    updatedPassword: HashedPassword;
}

export function UpdatePasswordFromJSON(json: any): UpdatePassword {
    return UpdatePasswordFromJSONTyped(json, false);
}

export function UpdatePasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': json['password'],
        'updatedPassword': HashedPasswordFromJSON(json['updatedPassword']),
    };
}

export function UpdatePasswordToJSON(value?: UpdatePassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'updatedPassword': HashedPasswordToJSON(value.updatedPassword),
    };
}


