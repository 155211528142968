/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
    FixedTransactionAmount,
    FixedTransactionAmountFromJSON,
    FixedTransactionAmountFromJSONTyped,
    FixedTransactionAmountToJSON,
    TimeRange,
    TimeRangeFromJSON,
    TimeRangeFromJSONTyped,
    TimeRangeToJSON,
} from './';

/**
 * Schema to update fixed transaction
 * @export
 * @interface UpdateFixedTransaction
 */
export interface UpdateFixedTransaction {
    /**
     * Identifier of the category that will be assigned to the fixed transaction
     * @type {number}
     * @memberof UpdateFixedTransaction
     */
    categoryId?: number;
    /**
     * 
     * @type {Amount}
     * @memberof UpdateFixedTransaction
     */
    amount?: Amount;
    /**
     * 
     * @type {TimeRange}
     * @memberof UpdateFixedTransaction
     */
    timeRange?: TimeRange;
    /**
     * Product of the fixed transaction
     * @type {string}
     * @memberof UpdateFixedTransaction
     */
    product?: string;
    /**
     * Description of the fixed transaction
     * @type {string}
     * @memberof UpdateFixedTransaction
     */
    description?: string;
    /**
     * Vendor of the fixed transaction
     * @type {string}
     * @memberof UpdateFixedTransaction
     */
    vendor?: string;
    /**
     * Indicates whether this transaction is variable or not
     * @type {boolean}
     * @memberof UpdateFixedTransaction
     */
    hasVariableAmounts?: boolean;
    /**
     * Billing day of the fixed transaction
     * @type {number}
     * @memberof UpdateFixedTransaction
     */
    day?: number;
    /**
     * List of fixed transaction amounts
     * @type {Array<FixedTransactionAmount>}
     * @memberof UpdateFixedTransaction
     */
    transactionAmounts?: Array<FixedTransactionAmount>;
}

export function UpdateFixedTransactionFromJSON(json: any): UpdateFixedTransaction {
    return UpdateFixedTransactionFromJSONTyped(json, false);
}

export function UpdateFixedTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFixedTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'timeRange': !exists(json, 'timeRange') ? undefined : TimeRangeFromJSON(json['timeRange']),
        'product': !exists(json, 'product') ? undefined : json['product'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'hasVariableAmounts': !exists(json, 'hasVariableAmounts') ? undefined : json['hasVariableAmounts'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'transactionAmounts': !exists(json, 'transactionAmounts') ? undefined : ((json['transactionAmounts'] as Array<any>).map(FixedTransactionAmountFromJSON)),
    };
}

export function UpdateFixedTransactionToJSON(value?: UpdateFixedTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'amount': AmountToJSON(value.amount),
        'timeRange': TimeRangeToJSON(value.timeRange),
        'product': value.product,
        'description': value.description,
        'vendor': value.vendor,
        'hasVariableAmounts': value.hasVariableAmounts,
        'day': value.day,
        'transactionAmounts': value.transactionAmounts === undefined ? undefined : ((value.transactionAmounts as Array<any>).map(FixedTransactionAmountToJSON)),
    };
}


