/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryToJSON,
    PagedModelFixedTransaction,
    PagedModelFixedTransactionFromJSON,
    PagedModelFixedTransactionToJSON,
    PagedModelVariableTransaction,
    PagedModelVariableTransactionFromJSON,
    PagedModelVariableTransactionToJSON,
    RegisterUser,
    RegisterUserFromJSON,
    RegisterUserToJSON,
    UpdatePassword,
    UpdatePasswordFromJSON,
    UpdatePasswordToJSON,
    UpdatePersonalInformation,
    UpdatePersonalInformationFromJSON,
    UpdatePersonalInformationToJSON,
    UpdateSettings,
    UpdateSettingsFromJSON,
    UpdateSettingsToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface DeleteTokenRequest {
    userId: number;
    tokenId: number;
    accept?: DeleteTokenAcceptEnum;
}

export interface GetUserRequest {
    userId: number;
    accept?: GetUserAcceptEnum;
}

export interface GetUsersCategoriesRequest {
    userId: number;
    accept?: GetUsersCategoriesAcceptEnum;
}

export interface GetUsersFixedTransactionsRequest {
    userId: number;
    onlyActive?: boolean;
    categoryId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
    accept?: GetUsersFixedTransactionsAcceptEnum;
}

export interface GetUsersVariableTransactionsRequest {
    userId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
    accept?: GetUsersVariableTransactionsAcceptEnum;
}

export interface LoginUserRequest {
    email: string;
    password: string;
    accept?: LoginUserAcceptEnum;
}

export interface RegisterUserRequest {
    registerUser: RegisterUser;
    accept?: RegisterUserAcceptEnum;
}

export interface UpdateUsersPasswordRequest {
    userId: number;
    updatePassword: UpdatePassword;
    accept?: UpdateUsersPasswordAcceptEnum;
}

export interface UpdateUsersPersonalInformationRequest {
    userId: number;
    updatePersonalInformation: UpdatePersonalInformation;
    accept?: UpdateUsersPersonalInformationAcceptEnum;
}

export interface UpdateUsersSettingsRequest {
    userId: number;
    updateSettings: UpdateSettings;
    accept?: UpdateUsersSettingsAcceptEnum;
}

export interface VerifyUserRequest {
    userId: number;
    verificationToken: string;
    accept?: VerifyUserAcceptEnum;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Deletes a token
     */
    async deleteTokenRaw(requestParameters: DeleteTokenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteToken.');
        }

        if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
            throw new runtime.RequiredError('tokenId','Required parameter requestParameters.tokenId was null or undefined when calling deleteToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/tokens/{tokenId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"tokenId"}}`, encodeURIComponent(String(requestParameters.tokenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a token
     */
    async deleteToken(requestParameters: DeleteTokenRequest): Promise<void> {
        await this.deleteTokenRaw(requestParameters);
    }

    /**
     * Returns a user
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Returns a user
     */
    async getUser(requestParameters: GetUserRequest): Promise<User> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetches all categories of the user
     */
    async getUsersCategoriesRaw(requestParameters: GetUsersCategoriesRequest): Promise<runtime.ApiResponse<Array<Category>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUsersCategories.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/categories`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryFromJSON));
    }

    /**
     * Fetches all categories of the user
     */
    async getUsersCategories(requestParameters: GetUsersCategoriesRequest): Promise<Array<Category>> {
        const response = await this.getUsersCategoriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetches all fixed transactions of the user
     */
    async getUsersFixedTransactionsRaw(requestParameters: GetUsersFixedTransactionsRequest): Promise<runtime.ApiResponse<PagedModelFixedTransaction>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUsersFixedTransactions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['onlyActive'] = requestParameters.onlyActive;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/fixedTransactions`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedModelFixedTransactionFromJSON(jsonValue));
    }

    /**
     * Fetches all fixed transactions of the user
     */
    async getUsersFixedTransactions(requestParameters: GetUsersFixedTransactionsRequest): Promise<PagedModelFixedTransaction> {
        const response = await this.getUsersFixedTransactionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetches all variable transactions of the user
     */
    async getUsersVariableTransactionsRaw(requestParameters: GetUsersVariableTransactionsRequest): Promise<runtime.ApiResponse<PagedModelVariableTransaction>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUsersVariableTransactions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/variableTransactions`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedModelVariableTransactionFromJSON(jsonValue));
    }

    /**
     * Fetches all variable transactions of the user
     */
    async getUsersVariableTransactions(requestParameters: GetUsersVariableTransactionsRequest): Promise<PagedModelVariableTransaction> {
        const response = await this.getUsersVariableTransactionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Logs the user into the system
     */
    async loginUserRaw(requestParameters: LoginUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling loginUser.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling loginUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Logs the user into the system
     */
    async loginUser(requestParameters: LoginUserRequest): Promise<User> {
        const response = await this.loginUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Registers a new user
     */
    async registerUserRaw(requestParameters: RegisterUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.registerUser === null || requestParameters.registerUser === undefined) {
            throw new runtime.RequiredError('registerUser','Required parameter requestParameters.registerUser was null or undefined when calling registerUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        const response = await this.request({
            path: `/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserToJSON(requestParameters.registerUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Registers a new user
     */
    async registerUser(requestParameters: RegisterUserRequest): Promise<User> {
        const response = await this.registerUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the users password
     */
    async updateUsersPasswordRaw(requestParameters: UpdateUsersPasswordRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUsersPassword.');
        }

        if (requestParameters.updatePassword === null || requestParameters.updatePassword === undefined) {
            throw new runtime.RequiredError('updatePassword','Required parameter requestParameters.updatePassword was null or undefined when calling updateUsersPassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/password`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordToJSON(requestParameters.updatePassword),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Updates the users password
     */
    async updateUsersPassword(requestParameters: UpdateUsersPasswordRequest): Promise<User> {
        const response = await this.updateUsersPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the users personal information
     */
    async updateUsersPersonalInformationRaw(requestParameters: UpdateUsersPersonalInformationRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUsersPersonalInformation.');
        }

        if (requestParameters.updatePersonalInformation === null || requestParameters.updatePersonalInformation === undefined) {
            throw new runtime.RequiredError('updatePersonalInformation','Required parameter requestParameters.updatePersonalInformation was null or undefined when calling updateUsersPersonalInformation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/personalInformation`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePersonalInformationToJSON(requestParameters.updatePersonalInformation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Updates the users personal information
     */
    async updateUsersPersonalInformation(requestParameters: UpdateUsersPersonalInformationRequest): Promise<User> {
        const response = await this.updateUsersPersonalInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the users settings
     */
    async updateUsersSettingsRaw(requestParameters: UpdateUsersSettingsRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUsersSettings.');
        }

        if (requestParameters.updateSettings === null || requestParameters.updateSettings === undefined) {
            throw new runtime.RequiredError('updateSettings','Required parameter requestParameters.updateSettings was null or undefined when calling updateUsersSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/settings`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSettingsToJSON(requestParameters.updateSettings),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Updates the users settings
     */
    async updateUsersSettings(requestParameters: UpdateUsersSettingsRequest): Promise<User> {
        const response = await this.updateUsersSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Verifies a users email address
     */
    async verifyUserRaw(requestParameters: VerifyUserRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling verifyUser.');
        }

        if (requestParameters.verificationToken === null || requestParameters.verificationToken === undefined) {
            throw new runtime.RequiredError('verificationToken','Required parameter requestParameters.verificationToken was null or undefined when calling verifyUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.verificationToken !== undefined) {
            queryParameters['verificationToken'] = requestParameters.verificationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{userId}/verificationToken`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Verifies a users email address
     */
    async verifyUser(requestParameters: VerifyUserRequest): Promise<object> {
        const response = await this.verifyUserRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum DeleteTokenAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetUserAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetUsersCategoriesAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetUsersFixedTransactionsAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum GetUsersVariableTransactionsAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum LoginUserAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum RegisterUserAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateUsersPasswordAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateUsersPersonalInformationAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateUsersSettingsAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum VerifyUserAcceptEnum {
    ApplicationJson = 'application/json'
}
