/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryClassEnum,
    CategoryClassEnumFromJSON,
    CategoryClassEnumFromJSONTyped,
    CategoryClassEnumToJSON,
} from './';

/**
 * Schema for a category
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * Identifier of the category
     * @type {number}
     * @memberof Category
     */
    id: number;
    /**
     * 
     * @type {CategoryClassEnum}
     * @memberof Category
     */
    categoryClass: CategoryClassEnum;
    /**
     * Id of the parent category
     * @type {number}
     * @memberof Category
     */
    parentId?: number;
    /**
     * Name of the category
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * List of the child categories
     * @type {Array<Category>}
     * @memberof Category
     */
    children?: Array<Category>;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'categoryClass': CategoryClassEnumFromJSON(json['categoryClass']),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'name': json['name'],
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(CategoryFromJSON)),
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'categoryClass': CategoryClassEnumToJSON(value.categoryClass),
        'parentId': value.parentId,
        'name': value.name,
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(CategoryToJSON)),
    };
}


