/* tslint:disable */
/* eslint-disable */
/**
 * Financer API
 * Financer is an application that helps to manage your personal expenses and revenues. It helps you to analyze how much you have spend on living, eating etc. Besides you can take a look at the temporal progression of your expenses as well as on your revenues.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: raphael@muesseler.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryToJSON,
    CreateCategory,
    CreateCategoryFromJSON,
    CreateCategoryToJSON,
    UpdateCategory,
    UpdateCategoryFromJSON,
    UpdateCategoryToJSON,
} from '../models';

export interface CreateCategoryRequest {
    createCategory: CreateCategory;
    accept?: CreateCategoryAcceptEnum;
}

export interface DeleteCategoryRequest {
    categoryId: number;
    accept?: DeleteCategoryAcceptEnum;
}

export interface UpdateCategoryRequest {
    categoryId: number;
    updateCategory: UpdateCategory;
    accept?: UpdateCategoryAcceptEnum;
}

/**
 * no description
 */
export class CategoryApi extends runtime.BaseAPI {

    /**
     * Creates a new category
     */
    async createCategoryRaw(requestParameters: CreateCategoryRequest): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.createCategory === null || requestParameters.createCategory === undefined) {
            throw new runtime.RequiredError('createCategory','Required parameter requestParameters.createCategory was null or undefined when calling createCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCategoryToJSON(requestParameters.createCategory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     * Creates a new category
     */
    async createCategory(requestParameters: CreateCategoryRequest): Promise<Category> {
        const response = await this.createCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a category
     */
    async deleteCategoryRaw(requestParameters: DeleteCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a category
     */
    async deleteCategory(requestParameters: DeleteCategoryRequest): Promise<void> {
        await this.deleteCategoryRaw(requestParameters);
    }

    /**
     * Updates a category
     */
    async updateCategoryRaw(requestParameters: UpdateCategoryRequest): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateCategory.');
        }

        if (requestParameters.updateCategory === null || requestParameters.updateCategory === undefined) {
            throw new runtime.RequiredError('updateCategory','Required parameter requestParameters.updateCategory was null or undefined when calling updateCategory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("TokenAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCategoryToJSON(requestParameters.updateCategory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     * Updates a category
     */
    async updateCategory(requestParameters: UpdateCategoryRequest): Promise<Category> {
        const response = await this.updateCategoryRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CreateCategoryAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum DeleteCategoryAcceptEnum {
    ApplicationJson = 'application/json'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCategoryAcceptEnum {
    ApplicationJson = 'application/json'
}
